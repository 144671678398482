import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';

export const getTerminalRequest = (id) => api.get(ENDPOINTS.TERMINAL.TERMINAL_DETAILS(id));

export const {
  loadDataAction: loadDataGetTerminalAction,
  doRequestAction: doRequestGetTerminalAction,
  forcedLoadDataAction: forcedLoadDataGetTerminalAction,
  requestFulfilledAction: requestFulfilledGetTerminalAction,
  requestRejectedAction: requestRejectedGetTerminalAction,
  responseSelector: responseGetTerminalSelector,
  errorSelector: errorGetTerminalSelector,
  isLoadedSelector: isLoadedGetTerminalSelector,
  resetRequestAction: resetRequestGetTerminalAction,
} = requestsFactory({
  request: getTerminalRequest,
  stateRequestKey: 'terminal_get',
});
