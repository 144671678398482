import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';

export const getDownloadFileUpdateRequest = ({ file_id, terminal_id }) =>
  api.get(ENDPOINTS.DOWNLOAD_FILE_UPDATE(file_id, terminal_id));

export const {
  loadDataAction: loadDataDownloadFileUpdateAction,
  doRequestAction: doRequestDownloadFileUpdateAction,
  resetRequestAction: resetRequestDownloadFileUpdateAction,
  forcedLoadDataAction: forcedLoadDataDownloadFileUpdateAction,
  requestFulfilledAction: requestFulfilledDownloadFileUpdateAction,
  requestRejectedAction: requestRejectedDownloadFileUpdateAction,
  requestStatusSelector: requestStatusDownloadFileUpdateSelector,
  responseSelector: responseDownloadFileUpdateSelector,
  errorSelector: errorDownloadFileUpdateSelector,
  isLoadedSelector: isLoadedDownloadFileUpdateSelector,
} = requestsFactory({
  request: getDownloadFileUpdateRequest,
  stateRequestKey: 'download_file_update_get',
});
