import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';

export const getKeyListRequest = (params) => api.get(ENDPOINTS.KEY.KEY_LIST(params));

export const {
  loadDataAction: loadDataGetKeyListAction,
  doRequestAction: doRequestGetKeyListAction,
  resetRequestAction: resetRequestGetKeyListAction,
  forcedLoadDataAction: forcedLoadDataGetKeyListAction,
  requestFulfilledAction: requestFulfilledGetKeyListAction,
  requestRejectedAction: requestRejectedGetKeyListAction,
  responseSelector: responseGetKeyListSelector,
  errorSelector: errorGetKeyListSelector,
  isLoadedSelector: isLoadedGetKeyListSelector,
} = requestsFactory({
  request: getKeyListRequest,
  stateRequestKey: 'key_list_get',
});
