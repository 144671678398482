import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { doRequestDownloadFileAction } from 'src/api/requests/download-file';
import { downloadFileAction } from './actions';

const downloadFileEpic = (action$) =>
  action$.pipe(
    ofType(downloadFileAction),
    map(({ payload }) => doRequestDownloadFileAction(payload))
  );

export { downloadFileEpic };
