import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { forcedLoadDataGetLocationListAction } from '../../../../api/requests/location-list';
import { initLocationListAction } from './actions';

const initLocationListEpic = (action$) =>
  action$.pipe(
    ofType(initLocationListAction),
    map(({ payload }) => forcedLoadDataGetLocationListAction(payload))
  );

export default initLocationListEpic;
