import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ROUTES, components } from './routes';

import AsyncRoute from './AsyncRoute';
import { API_TOKEN } from 'src/pages/Login/constants';
import localStorageFactory from 'src/localStorage';

const Router = () => {
  const token = localStorageFactory.getItem(API_TOKEN);

  return (
    <Switch>
      <Redirect exact from="/" to={token ? ROUTES.TERMINAL_LIST : ROUTES.LOGIN} />
      {components.map(({ path, component }) => (
        <AsyncRoute key={path} path={path} component={component} />
      ))}
      <Route
        path="*"
        component={() => (
          <h1
            style={{
              display: 'flex',
              height: '100vh',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            404 NOT FOUND
          </h1>
        )}
      />
    </Switch>
  );
};

export default Router;
