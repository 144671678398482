import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

const useDataResetOnSave = (loadedSelector, resetAction) => {
  const { goBack } = useHistory();
  const dispatch = useDispatch();
  const isLoaded = useSelector(loadedSelector);

  useEffect(() => {
    if (isLoaded) {
      dispatch(resetAction());
      goBack();
    }
  }, [isLoaded]);
};

export default useDataResetOnSave;

