import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';

export const getClientRequest = (id) => api.get(ENDPOINTS.CLIENT.CLIENT_DETAILS(id));

export const {
  loadDataAction: loadDataGetClientAction,
  doRequestAction: doRequestGetClientAction,
  forcedLoadDataAction: forcedLoadDataGetClientAction,
  resetRequestAction: resetRequestGetClientAction,
  requestFulfilledAction: requestFulfilledGetClientAction,
  requestRejectedAction: requestRejectedGeClientAction,
  responseSelector: responseGetClientSelector,
  requestStatusSelector: requestStatusGetClientSelector,
  errorSelector: errorGetClientSelector,
  isLoadedSelector: isLoadedGetClientSelector,
} = requestsFactory({
  request: getClientRequest,
  stateRequestKey: 'client_get',
});
