import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { forcedLoadDataGetUserAction } from '../../../../api/requests/user/get';
import { initUserInfoAction } from './actions';

const initUserInfoEpic = (action$) =>
  action$.pipe(
    ofType(initUserInfoAction),
    map(({ payload }) => forcedLoadDataGetUserAction(payload))
  );

export default initUserInfoEpic;
