import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';

export const getLocationRequest = (id) => api.get(ENDPOINTS.LOCATION.LOCATION_DETAILS(id));

export const {
  loadDataAction: loadDataGetLocationAction,
  doRequestAction: doRequestGetLocationAction,
  forcedLoadDataAction: forcedLoadDataGetLocationAction,
  requestFulfilledAction: requestFulfilledGetLocationAction,
  requestRejectedAction: requestRejectedGetLocationAction,
  responseSelector: responseGetLocationSelector,
  errorSelector: errorGetLocationSelector,
  isLoadedSelector: isLoadedGetLocationSelector,
  resetRequestAction: resetRequestGetLocationAction,
} = requestsFactory({
  request: getLocationRequest,
  stateRequestKey: 'location_get',
});
