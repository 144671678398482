import { combineEpics } from 'redux-observable';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { forcedLoadDataVersionsAction } from 'src/api/requests/versions';
import { initVersionsAction } from './actions';
import { downloadFileEpic } from '../components/DownloadUpdate/redux-management/epics';

const initVersionsEpic = (action$) =>
  action$.pipe(
    ofType(initVersionsAction),
    map(({ payload }) => forcedLoadDataVersionsAction(payload))
  );

export default combineEpics(initVersionsEpic, downloadFileEpic);
