import axios from 'axios';
import { getServerPath } from '../utils/location';
import config from '../config';
import { API_TOKEN, REFRESH_TOKEN } from '../pages/Login/constants';
import localStorageFactory from 'src/localStorage';
import { ENDPOINTS } from './constants';

const { requestAcceptHeader } = config.api;

const refreshToken = async (err) => {
  let refreshToken = localStorageFactory.getItem(REFRESH_TOKEN);
  const originalConfig = err.config;
  if (err.response) {
    if (err.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      try {
        const {
          data: { access_token, refresh_token },
        } = await instance().post(ENDPOINTS.REFRESH_TOKEN, {
          refresh_token: refreshToken,
          client_id: 'Rq7HdrITcLqAPxqyh2fS12dzqnM3nuU30w8ZizyQ',
          client_secret:
            'oabwCv6Udcrnq4NZDxHGIdnlFRyqAk7AXQU5gOjhvtaaIJlj9LqukjrYTHQBZnnXrqTcq7QT3CcY3opdiguNpJokN4dj9L8oGSZtzY8XPlQCmwwggnXjviT1mNQGLkZb',
          grant_type: 'refresh_token',
        });
        localStorageFactory.setItem(API_TOKEN, access_token);
        localStorageFactory.setItem(REFRESH_TOKEN, refresh_token);
        instance().defaults.headers.common['Authorization'] = access_token;

        window.location.reload();
        return instance()(originalConfig);
      } catch (_error) {
        if (_error.response && _error.response.results) {
          return Promise.reject(_error.response.results);
        }
        return Promise.reject(_error);
      }
    }
    if (err.response.status === 403 && err.response.data) {
      return Promise.reject(err.response.data);
    }
  }
  return Promise.reject(err);
};

export const instance = () => {
  const token = localStorageFactory.getItem(API_TOKEN);
  return axios.create({
    baseURL: getServerPath(''),
    headers: {
      Accept: requestAcceptHeader,
      Authorization: `Bearer ${token}`,
      'content-type': requestAcceptHeader,
    },
  });
};

export const api = {
  get: (url, config = {}, params) =>
    instance()
      .get(url, null, {
        params,
        responseType: 'json',
        ...config,
      })
      .then(({ data }) => data)
      .catch((err) => refreshToken(err)),
  post: (url, data, config) =>
    instance()
      .post(url, data, config)
      .then(({ data }) => data)
      .catch((err) => refreshToken(err)),
  patch: (url, data, config) =>
    instance()
      .patch(url, data, config)
      .then(({ data }) => data)
      .catch((err) => refreshToken(err)),
  put: (url, data, config) =>
    instance()
      .put(url, data, config)
      .then(({ data }) => data)
      .catch((err) => refreshToken(err)),
  delete: (url, data, config) =>
    instance()
      .delete(url, { ...config, data })
      .then(({ data }) => data)
      .catch((err) => refreshToken(err)),
};
