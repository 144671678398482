import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';
import { instance } from 'src/api';

export const getTerminalListRequest = (params) => api.get(ENDPOINTS.TERMINAL.TERMINAL_LIST(params));

export const {
  loadDataAction: loadDataGetTerminalListAction,
  doRequestAction: doRequestGetTerminalListAction,
  resetRequestAction: resetRequestGetTerminalListAction,
  forcedLoadDataAction: forcedLoadDataGetTerminalListAction,
  requestFulfilledAction: requestFulfilledGetTerminalListAction,
  requestRejectedAction: requestRejectedGetTerminalListAction,
  responseSelector: responseGetTerminalListSelector,
  errorSelector: errorGetTerminalListSelector,
  isLoadedSelector: isLoadedGetTerminalListSelector,
} = requestsFactory({
  request: getTerminalListRequest,
  stateRequestKey: 'terminal_list_get',
  transformError: (error) => error?.message,
});
