import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useInit = (onInit, params = null, extraDeps = []) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onInit(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onInit, dispatch, ...extraDeps]);
};

export default useInit;
