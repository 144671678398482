import React, { useState, useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Router } from './router';
import { useDispatch, useSelector } from 'react-redux';
import { history } from './store';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18next/i18n';
import Loading from './components/atoms/Loading';
import './App.css';
import PreperationAlert from './components/atoms/PreperationAlert';
import {
  errorDownloadFileUpdateSelector,
  requestStatusDownloadFileUpdateSelector,
  resetRequestDownloadFileUpdateAction,
  responseDownloadFileUpdateSelector,
} from './api/requests/download-file-update';
import {
  requestStatusDownloadFileSelector,
  resetRequestDownloadFileAction,
  responseDownloadFileSelector,
} from './api/requests/download-file';
import { LOADED_STATUS } from './constants';
import { exceptionError401 } from './utils/exceptionError401';
import { useErrorReset } from './hooks';
import ErrorModal from './components/atoms/ErrorModal';

const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isDownloadUpdate, setIsDownLoadUpdate] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [displayedError, setDisplayedError] = useState('');
  const errorMessageDownloadFileUpdate = useSelector(errorDownloadFileUpdateSelector);
  const { update_file: updateFileTerminal } = useSelector(responseDownloadFileUpdateSelector) || {};
  const { update_file } = useSelector(responseDownloadFileSelector) || {};
  const statusDownload = useSelector(requestStatusDownloadFileUpdateSelector);
  const statusUpdateFile = useSelector(requestStatusDownloadFileSelector);
  const isLoadingFile = statusDownload === LOADED_STATUS.LOADING || statusUpdateFile === LOADED_STATUS.LOADING;
  const errorResetMappings = [
    { message: errorMessageDownloadFileUpdate?.message, resetAction: resetRequestDownloadFileUpdateAction },
  ];

  const handleOccurredError = (message) => {
    setDisplayedError(message);
    if (exceptionError401(message)) {
      setShowErrorModal(true);
    }
  };
  const handleOnResetError = () => {
    setShowErrorModal(false);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    setIsDownLoadUpdate(isLoadingFile);
    if (update_file) {
      window?.open(update_file);
      dispatch(resetRequestDownloadFileAction());
    }
    if (updateFileTerminal) {
      window?.open(updateFileTerminal, '_parent', 'popup=yes');
      dispatch(resetRequestDownloadFileUpdateAction());
    }
  }, [dispatch, isLoadingFile, updateFileTerminal, update_file]);

  const handleHideErrorModal = useErrorReset(errorResetMappings, handleOccurredError, handleOnResetError);

  return (
    <ConnectedRouter history={history}>
      <I18nextProvider i18n={i18n}>
        {loading ? (
          <Loading />
        ) : (
          <div className="App">
            <ErrorModal errorMessage={displayedError} show={showErrorModal} onHide={handleHideErrorModal} />
            <Router />
            {isDownloadUpdate && <PreperationAlert />}
          </div>
        )}
      </I18nextProvider>
    </ConnectedRouter>
  );
};

export default App;
