import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';

export const getLocationListRequest = (params) => api.get(ENDPOINTS.LOCATION.LOCATION_LIST(params));

export const {
  loadDataAction: loadDataGetLocationListAction,
  doRequestAction: doRequestGetLocationListAction,
  resetRequestAction: resetRequestGetLocationListAction,
  forcedLoadDataAction: forcedLoadDataGetLocationListAction,
  requestFulfilledAction: requestFulfilledGetLocationListAction,
  requestRejectedAction: requestRejectedGetLocationListAction,
  responseSelector: responseGetLocationListSelector,
  errorSelector: errorGetLocationListSelector,
  isLoadedSelector: isLoadedGetLocationListSelector,
} = requestsFactory({
  request: getLocationListRequest,
  stateRequestKey: 'location_list_get',
});
