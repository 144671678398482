import { combineEpics } from 'redux-observable';
import initClientListEpic from '../../pages/Clients/redux-management/epics';

import terminalListEpic from '../../pages/Terminals/redux-management/epics';
import initUserListEpic from '../../pages/Users/redux-management/epics';
import initHubListEpic from '../../pages/Hubs/redux-management/epics';
import initKeyListEpic from '../../pages/Keys/redux-management/epics';
import initLocationListEpic from '../../pages/Locations/redux-management/epics';
import initVersionsEpic from 'src/pages/Versions/redux-management/epics';

export default combineEpics(
  terminalListEpic,
  initUserListEpic,
  initClientListEpic,
  initHubListEpic,
  initLocationListEpic,
  initKeyListEpic,
  initVersionsEpic
);
