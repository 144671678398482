import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';
import { forcedLoadDataGetUserAction } from './get';

export const patchUserRequest = ({ id, first_name, last_name, is_active }) =>
  api.patch(ENDPOINTS.USER.USER_DETAILS(id), { first_name, last_name, is_active });

export const {
  doRequestAction: doPatchUserRequestAction,
  requestFulfilledAction: patchUserRequestFulfilledAction,
  requestRejectedAction: patchUserRequestRejectedAction,
  resetRequestAction: resetRequestPatchUserAction,
  requestStatusSelector: requestStatusPatchUserSelector,
  errorSelector: errorPacthUserSelector,
  responseSelector: responsePatchUserSelector,
  isLoadedSelector: isLoadedPatchUserSelector,
} = requestsFactory({
  request: patchUserRequest,
  stateRequestKey: 'user_patch',
  fulfilledActions: [({ _, request: { id } }) => forcedLoadDataGetUserAction(id)],
});
