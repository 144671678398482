import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';

export const getUserRequest = (id) => api.get(ENDPOINTS.USER.USER_DETAILS(id));

export const {
  loadDataAction: loadDataGetUserAction,
  doRequestAction: doRequestGetUserAction,
  forcedLoadDataAction: forcedLoadDataGetUserAction,
  requestFulfilledAction: requestFulfilledGetUserAction,
  requestRejectedAction: requestRejectedGetUserAction,
  responseSelector: responseGetUserSelector,
  errorSelector: errorGetUserSelector,
  isLoadedSelector: isLoadedGetUserSelector,
  resetRequestAction: resetRequestGetUserAction,
} = requestsFactory({
  request: getUserRequest,
  stateRequestKey: 'user_get',
});
