import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { forcedLoadDataGetHubAction } from '../../../../api/requests/hub/get';
import { hubInfoAction } from './actions';

const initHubInfoEpic = (action$) =>
  action$.pipe(
    ofType(hubInfoAction),
    map(({ payload }) => forcedLoadDataGetHubAction(payload))
  );

export default initHubInfoEpic;
