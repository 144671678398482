import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';

export const getTerminalCommentRequest = (terminalId) => api.get(ENDPOINTS.TERMINAL.TERMINAL_COMMENT_LIST(terminalId));

export const {
  loadDataAction: loadDataGetTerminalCommentAction,
  doRequestAction: doRequestGetTerminalCommentAction,
  forcedLoadDataAction: forcedLoadDataGetTerminalCommentAction,
  requestFulfilledAction: requestFulfilledGetTerminalCommentAction,
  requestRejectedAction: requestRejectedGetTerminalCommentAction,
  responseSelector: responseGetTerminalCommentSelector,
  errorSelector: errorGetTerminalCommentSelector,
  isLoadedSelector: isLoadedGetTerminalCommentSelector,
} = requestsFactory({
  request: getTerminalCommentRequest,
  stateRequestKey: 'terminal_comment_get',
});
