import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';

export const getHubRequest = (id) => api.get(ENDPOINTS.HUB.HUB_DETAILS(id));

export const {
  loadDataAction: loadDataGetHubAction,
  doRequestAction: doRequestGetHubAction,
  forcedLoadDataAction: forcedLoadDataGetHubAction,
  resetRequestAction: resetRequestGetHubAction,
  requestFulfilledAction: requestFulfilledGetHubAction,
  requestRejectedAction: requestRejectedGetHubAction,
  responseSelector: responseGetHubSelector,
  errorSelector: errorGetHubSelector,
  isLoadedSelector: isLoadedGetHubSelector,
} = requestsFactory({
  request: getHubRequest,
  stateRequestKey: 'hub_get',
});
