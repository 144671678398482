import React from 'react';
import { Route } from 'react-router-dom';
import Loadable from './Loadable';

function AsyncRoute(props) {
  const { component, ...other } = props;
  const LoadableComponent = React.useMemo(() => Loadable({ loader: component }), [component]);
  return <Route component={LoadableComponent} {...other} />;
}

export default React.memo(AsyncRoute);
