export const ROUTES = {
  SET_PASSWORD: '/create-password',
  LOGIN: '/login',
  USER_LIST: '/user-list',
  USER_INFO: '/user-list/:id',
  USER_EDIT: (id) => `/user-list/${id}/edit`,
  CREATE_USER: '/create-user',
  TERMINAL_LIST: '/terminal-list',
  TERMINAL_INFO: '/terminal-list/:id',
  TERMINAL_EDIT: (id) => `/terminal-list/${id}/edit`,
  CREATE_TERMINAL: '/create-terminal',
  COMMENTS: (id) => `${ROUTES.TERMINAL_LIST}/${id}/comments`,
  HUB_LIST: '/hub-list',
  HUB_INFO: '/hub-list/:id',
  HUB_EDIT: (id) => `${ROUTES.HUB_LIST}/${id}/edit`,
  CLIENT_LIST: '/client-list',
  CLIENT_INFO: '/client-list/:id',
  CLIENT_EDIT: (id) => `${ROUTES.CLIENT_LIST}/${id}/edit`,
  CREATE_CLIENT: '/create-client',
  KEY_LIST: '/key-list',
  KEY_INFO: '/key-list/:id',
  KEY_EDIT: (id) => `${ROUTES.KEY_LIST}/${id}/edit`,
  LOCATION_LIST: '/location-list',
  LOCATION_INFO: '/location-list/:id',
  LOCATION_EDIT: (id) => `${ROUTES.LOCATION_LIST}/${id}/edit`,
  CREATE_LOCATION: '/create-location',
  IMPORT_KEY: (id) => `${ROUTES.TERMINAL_LIST}/${id}/import-key`,
  VERSIONS: '/versions-specto-vms',
  VERSIONS_UPDATE_VMS: '/versions-update-vms',
  VERSIONS_SPECTO_REMOTE: '/versions-specto-remote',
  VERSIONS_SPECTO_DM: '/versions-specto-dm',
};

export const components = [
  {
    path: ROUTES.LOGIN,
    component: () => import('src/pages/Login'),
  },
  {
    path: ROUTES.USER_LIST,
    component: () => import('src/pages/Users'),
  },
  {
    path: ROUTES.CREATE_USER,
    component: () => import('src/pages/Users/CreateUser'),
  },
  {
    path: ROUTES.CLIENT_LIST,
    component: () => import('src/pages/Clients'),
  },
  {
    path: ROUTES.CREATE_CLIENT,
    component: () => import('src/pages/Clients/CreateClient'),
  },
  {
    path: ROUTES.TERMINAL_LIST,
    component: () => import('src/pages/Terminals'),
  },
  {
    path: ROUTES.CREATE_TERMINAL,
    component: () => import('src/pages/Terminals/CreateTerminal'),
  },
  {
    path: ROUTES.HUB_LIST,
    component: () => import('src/pages/Hubs'),
  },
  {
    path: ROUTES.LOCATION_LIST,
    component: () => import('src/pages/Locations'),
  },
  {
    path: ROUTES.CREATE_LOCATION,
    component: () => import('src/pages/Locations/CreateLocation'),
  },
  {
    path: ROUTES.KEY_LIST,
    component: () => import('src/pages/Keys'),
  },
  {
    path: ROUTES.SET_PASSWORD,
    component: () => import('src/pages/CreatePassword'),
  },
  {
    path: ROUTES.VERSIONS,
    component: () => import('src/pages/Versions'),
  },
  {
    path: ROUTES.VERSIONS_UPDATE_VMS,
    component: () => import('src/pages/Versions'),
  },
  {
    path: ROUTES.VERSIONS_SPECTO_REMOTE,
    component: () => import('src/pages/Versions'),
  },
  {
    path: ROUTES.VERSIONS_SPECTO_DM,
    component: () => import('src/pages/Versions'),
  },
];
