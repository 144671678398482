import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { forcedLoadDataGetHubListAction } from '../../../../api/requests/hub-list';
import { initHubListAction } from './actions';

const initHubListEpic = (action$) =>
  action$.pipe(
    ofType(initHubListAction),
    map(({ payload }) => forcedLoadDataGetHubListAction(payload))
  );

export default initHubListEpic;
