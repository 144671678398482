import React from 'react';
import Loading from '../../components/atoms/Loading';

const Loadable = ({ loader, withProps = false }) => {
  return React.memo((ownProps) => {
    const [{ Component }, setImp] = React.useState({ Component: null });

    React.useEffect(() => {
      loader().then((imp) => {
        setImp({ Component: imp && imp.__esModule ? imp.default : imp });
      });
    }, [setImp]);

    const props = withProps ? ownProps : {};

    return (
      <>
        {Component ? <Component {...props} /> : null}
        <Loading isLoading={!Component} />
      </>
    );
  });
};

export default Loadable;
