import { combineEpics, ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { terminalInfoAction } from './actions';
import { doRequestGetTerminalAction } from '../../../../api/requests/terminal';
import initTerminalInfoCommentEpic from '../components/Comments/redux-management/epic';
import initTerminalInfoHistoryEpic from '../components/HistoryChange/redux-management/epic';

const initTerminalInfoEpic = (action$) =>
  action$.pipe(
    ofType(terminalInfoAction),
    mergeMap(({ payload }) => [doRequestGetTerminalAction(payload)])
  );

export default combineEpics(initTerminalInfoEpic, initTerminalInfoCommentEpic, initTerminalInfoHistoryEpic);
