import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { doRequestGetTerminalListAction } from '../../../../api/requests/terminal-list';
import { initTerminalListAction } from './actions';

const initTerminalListEpic = (action$) =>
  action$.pipe(
    ofType(initTerminalListAction),
    map(({ payload }) => doRequestGetTerminalListAction(payload))
  );

export default initTerminalListEpic;
