import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { terminalInfoHistoryAction } from './action';
import { forcedLoadDataGetTerminalHistoryAction } from 'src/api/requests/terminal-history';

const initTerminalInfoHistoryEpic = (action$) =>
  action$.pipe(
    ofType(terminalInfoHistoryAction),
    map(({ payload }) => forcedLoadDataGetTerminalHistoryAction(payload))
  );

export default initTerminalInfoHistoryEpic;
