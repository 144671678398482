import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { keyInfoAction } from './action';
import { forcedLoadDataGetKeyAction } from '../../../../api/requests/key';

const initKeyInfoEpic = (action$) =>
  action$.pipe(
    ofType(keyInfoAction),
    map(({ payload }) => forcedLoadDataGetKeyAction(payload))
  );

export default initKeyInfoEpic;
