import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';

export const getClientListRequest = (params) => api.get(ENDPOINTS.CLIENT.CLIENT_LIST(params));

export const {
  loadDataAction: loadDataGetClientListAction,
  doRequestAction: doRequestGetClientListAction,
  forcedLoadDataAction: forcedLoadDataGetClientListAction,
  resetRequestAction: resetRequestGetClientListAction,
  requestFulfilledAction: requestFulfilledGetClientListAction,
  requestRejectedAction: requestRejectedGeClientListAction,
  responseSelector: responseGetClientListSelector,
  errorSelector: errorGetClientListSelector,
  isLoadedSelector: isLoadedGetClientListSelector,
} = requestsFactory({
  request: getClientListRequest,
  stateRequestKey: 'client_list_get',
});
