import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { forcedLoadDataGetUserListAction } from '../../../../api/requests/user-list';

import { initUserListAction } from './actions';

const initUserListEpic = (action$) =>
  action$.pipe(
    ofType(initUserListAction),
    map(({ payload }) => forcedLoadDataGetUserListAction(payload))
  );

export default initUserListEpic;
