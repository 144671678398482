import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';
import { forcedLoadDataGetTerminalAction } from './get';
import { forcedLoadDataGetTerminalHistoryAction } from '../terminal-history';

export const patchTerminalRequest = ({
  id,
  name,
  is_active,
  description,
  installation_date,
  location,
  contract,
  hubs,
  key,
}) =>
  api.patch(ENDPOINTS.TERMINAL.TERMINAL_DETAILS(id), {
    name,
    is_active,
    description,
    installation_date,
    location,
    contract,
    hubs,
    key,
  });

export const {
  loadDataAction: loadDataPatchTerminalAction,
  doRequestAction: doRequestPatchTerminalAction,
  forcedLoadDataAction: forcedLoadDataPatchTerminalAction,
  resetRequestAction: resetRequestPatchTerminalAction,
  requestFulfilledAction: requestFulfilledPatchTerminalAction,
  requestRejectedAction: requestRejectedPatchTerminalAction,
  responseSelector: responsePatchTerminalSelector,
  errorSelector: errorPatchTerminalSelector,
  isLoadedSelector: isLoadedPatchTerminalSelector,
} = requestsFactory({
  request: patchTerminalRequest,
  stateRequestKey: 'terminal_patch',
  fulfilledActions: [
    ({ _, request: { id } }) => [forcedLoadDataGetTerminalAction(id), forcedLoadDataGetTerminalHistoryAction(id)],
  ],
});
