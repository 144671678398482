import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';

export const getKeyRequest = (id) => api.get(ENDPOINTS.KEY.KEY_DETAILS(id));

export const {
  loadDataAction: loadDataGetKeyAction,
  doRequestAction: doRequestGetKeyAction,
  resetRequestAction: resetRequestGetKeyAction,
  forcedLoadDataAction: forcedLoadDataGetKeyAction,
  requestFulfilledAction: requestFulfilledGetKeyAction,
  requestRejectedAction: requestRejectedGetKeyAction,
  responseSelector: responseGetKeySelector,
  errorSelector: errorGetKeySelector,
  isLoadedSelector: isLoadedGetKeySelector,
} = requestsFactory({
  request: getKeyRequest,
  stateRequestKey: 'key_get',
});
