import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { forcedLoadDataGetClientListAction } from '../../../../api/requests/client-list';
import { initClientListAction } from './actions';

const initClientListEpic = (action$) =>
  action$.pipe(
    ofType(initClientListAction),
    map(({ payload }) => forcedLoadDataGetClientListAction(payload))
  );

export default initClientListEpic;
