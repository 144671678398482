import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';

export const getTerminalHistoryRequest = (id) => api.get(ENDPOINTS.TERMINAL.TERMINAL_HISTORY(id));

export const {
  loadDataAction: loadDataGetTerminalHistoryAction,
  doRequestAction: doRequestGetTerminalHistoryAction,
  forcedLoadDataAction: forcedLoadDataGetTerminalHistoryAction,
  requestFulfilledAction: requestFulfilledGetTerminalHistoryAction,
  requestRejectedAction: requestRejectedGetTerminalHistoryAction,
  responseSelector: responseGetTerminalHistorySelector,
  errorSelector: errorGetTerminalHistorySelector,
  isLoadedSelector: isLoadedGetTerminalHistorySelector,
} = requestsFactory({
  request: getTerminalHistoryRequest,
  stateRequestKey: 'terminal_history_get',
});
