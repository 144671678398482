import React from 'react';
import logo from '../../../image/logo.png';
import { CSSTransition } from 'react-transition-group';

const DEFAULT_DURATION = 300;

const Loading = ({ isLoading }) => {
  return (
    <CSSTransition
      in={isLoading}
      appear
      mountOnEnter
      unmountOnExit
      timeout={DEFAULT_DURATION}
      classNames={{
        appear: 'fadeIn',
        appearActive: 'fadeIn',
        appearDone: 'fadeIn',
        enter: 'fadeIn',
        enterActive: 'fadeIn',
        enterDone: 'fadeIn',
        exit: 'fadeOut',
        exitActive: 'fadeOut',
        exitDone: 'fadeOut',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          top: '0',
          right: '0',
          bottom: '0',
          left: '0',
          position: 'absolute',
          background: '#fff',
          zIndex: 2000,
        }}
      >
        <img src={logo} alt="loading" />
      </div>
    </CSSTransition>
  );
};

export default Loading;
