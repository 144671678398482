import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';

export const getVersionsRequest = (params) => api.get(ENDPOINTS.VERSIONS(params));

export const {
  loadDataAction: loadDataVersionsAction,
  doRequestAction: doRequestVersionsAction,
  resetRequestAction: resetRequestVersionsAction,
  forcedLoadDataAction: forcedLoadDataVersionsAction,
  resetRequestAction: resetVersionsAction,
  requestFulfilledAction: requestFulfilledVersionsAction,
  requestRejectedAction: requestRejectedVersionsAction,
  responseSelector: responseVersionsSelector,
  errorSelector: errorVersionsSelector,
  isLoadedSelector: isLoadedVersionsSelector,
} = requestsFactory({
  request: getVersionsRequest,
  stateRequestKey: 'versions_get',
});
