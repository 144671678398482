import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';

export const getHubListRequest = (params) => api.get(ENDPOINTS.HUB.HUB_LIST(params));

export const {
  loadDataAction: loadDataGetHubListAction,
  doRequestAction: doRequestGetHubListAction,
  forcedLoadDataAction: forcedLoadDataGetHubListAction,
  resetRequestAction: resetRequestGetHubListAction,
  requestFulfilledAction: requestFulfilledGetHubListAction,
  requestRejectedAction: requestRejectedGetHubListAction,
  responseSelector: responseGetHubListSelector,
  errorSelector: errorGetHubListSelector,
  isLoadedSelector: isLoadedGetHubListSelector,
} = requestsFactory({
  request: getHubListRequest,
  stateRequestKey: 'hub_list_get',
});
