export const LOADED_STATUS = {
  ERROR: 'failed',
  SUCCESS: 'success',
  FAILED: 'failed',
  LOADING: 'loading',
  NONE: 'none',
};

export const PAGE_TITLE = {
  USER_MANAGER: 'Менеджер пользователей',
  CLIENT_MANAGER: 'Менеджер клиентов',
  TERMINAL_MANAGER: 'Менеджер терминалов',
  HUB_MANAGER: 'Менеджер хабов',
  LOCATION_MANAGER: 'Менеджер локаций',
  KEY_MANAGER: 'Менеджер аппаратных ключей',
  VERSIONS_MANAGER: 'Версии программ и обновлений',
};

export const REQUESTED_OBJECT = {
  NOT_EXSITS: 'not_exists',
};

export const TABS = {
  SPECTO_VMS: 'SpectoVMS',
  UPDATE_VMS: 'UpdateVMS',
  SPECTO_REMOTE: 'SpectoRemote',
  SPECTO_DM: 'SpectoDM',
};
