import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { forcedLoadDataGetKeyListAction } from '../../../../api/requests/key-list';
import { initKeyListAction } from './action';

const initKeyListEpic = (action$) =>
  action$.pipe(
    ofType(initKeyListAction),
    map(({ payload }) => forcedLoadDataGetKeyListAction(payload))
  );

export default initKeyListEpic;
