import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { formatServerErrors } from '../../../utils/formatServerErrors';
import { ModalContent, Span, Button, ModalFooter, ModalHeader } from './ErrorModal.styles';
import { REQUESTED_OBJECT } from 'src/constants/constants';

const ErrorModal = ({ onHide, errorMessage, ...props }) => {
  const { goBack } = useHistory();
  const { t } = useTranslation('translations');
  const message = formatServerErrors(errorMessage);
  
  const handleOnHide = () => {
    onHide();
    if (errorMessage === REQUESTED_OBJECT.NOT_EXSITS) {
      goBack();
    }
  };

  return (
    <Modal {...props} dialogClassName="modal-60w" aria-labelledby="contained-modal-title-vcenter" centered>
      <ModalContent>
        <ModalHeader>
          <Modal.Title id="contained-modal-title-vcenter">{t('user_management.error_message.title')}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Span>{t(message)}</Span>
        </Modal.Body>
        <ModalFooter>
          <Button onClick={handleOnHide}>{t('user_management.error_message.button')}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ErrorModal;
