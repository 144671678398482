import React from 'react';
import { Alert } from 'react-bootstrap';

const PreperationAlert = () => (
  <Alert
    style={{
      position: 'fixed',
      bottom: 0,
      right: '10px',
      boxShadow: '10px 7px 12px 1px rgba(0,0,0,0.12)',
      borderRadius: '10px',
    }}
    key="light"
    variant="light"
  >
    Подготовка файла (-ов) к скачиванию
  </Alert>
);

export default PreperationAlert;
