import { url } from './utils/url';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  api: {
    prefix: '/api',
    requestAcceptHeader: 'application/json',
    serverUrl: url(),
    isFullPathEnabled: false,
  },
};
