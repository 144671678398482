export const ENDPOINTS = {
  TERMINAL: {
    TERMINAL_LIST: (params) => `api/v1/terminal?${params || ''}`,
    TERMINAL_DETAILS: (id) => `api/v1/terminal/${id}`,
    TERMINAL_DELETE: (id) => `api/v1/terminal/${id}`,
    TERMINAL_COMMENT_LIST: (terminalId) => `api/v1/terminal_comment?terminal=${terminalId}`,
    TERMINAL_COMMENT: 'api/v1/terminal_comment',
    TERMINAL_HISTORY: (id) => `api/v1/terminal_history/${id}`,
    TERMINAL_GENERATE_LICENSE: (id) => `api/v1/terminal/${id}/generate_license`,
    TERMINAL_IMPORT_KEY: '/api/v1/key_terminal_file',
  },
  HUB: {
    HUB_LIST: (params) => `api/v1/hub?${params || ''}`,
    HUB_DETAILS: (id) => `api/v1/hub/${id}`,
  },
  KEY: {
    KEY_LIST: (params) => `api/v1/key?${params || ''}`,
    KEY_DETAILS: (id) => `api/v1/key/${id}`,
  },
  LOCATION: {
    LOCATION_LIST: (params) => `api/v1/location?${params || ''}`,
    LOCATION_DETAILS: (id) => `api/v1/location/${id}`,
  },
  CLIENT: {
    CLIENT_LIST: (params) => `api/v1/client?${params || ''}`,
    CLIENT_DETAILS: (id) => `api/v1/client/${id}`,
  },
  USER: {
    USER_LIST: (params) => `api/v1/user?${params || ''}`,
    USER_DETAILS: (id) => `api/v1/user/${id}`,
  },
  LOGIN: 'api/auth/token/',
  LOGOUT: 'api/rest-auth/logout/',
  SET_PASSWORD: 'api/v1/set_password/',
  CHECK_STATUS_TOKEN: (token) => `api/v1/authentication_key/check_status?key=${token}`,
  AUTHENTICATION_KEY: 'api/v1/authentication_key',
  REFRESH_TOKEN: 'api/auth/token/',
  VERSIONS: (params) => `api/v1/update_file?${params || ''}`,
  DOWNLOAD_FILE: (id) => `api/v1/update_file/${id}/download_file`,
  DOWNLOAD_FILE_UPDATE: (file_id, terminal_id) =>
    `api/v1/update_file/${file_id}/generate_terminal_update/${terminal_id}`,
};
