import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import store from './store';
import { Provider, ReactReduxContext } from 'react-redux';

ReactDOM.render(
  <Provider store={store} context={ReactReduxContext}>
    <App />
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
