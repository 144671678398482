import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AsyncRoute from './AsyncRoute';

const PageRouter = ({ routes }) => {
  return (
    <Switch>
      {routes.map(({ path, component }) => (
        <AsyncRoute exact key={path} path={path} component={component} />
      ))}
      <Route
        path="*"
        component={() => (
          <h1
            style={{
              display: 'flex',
              height: '100vh',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            404 NOT FOUND
          </h1>
        )}
      />
    </Switch>
  );
};

export default PageRouter;
