import { useState, useEffect } from 'react';

const useAsyncState = (asyncData) => {
  const [value, setValue] = useState('');
  useEffect(() => {
    setValue({ value: asyncData });
  }, [asyncData]);

  return [value, setValue];
};

export default useAsyncState;
