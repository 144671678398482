import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createEpicMiddleware } from 'redux-observable';
import epics from './epics';
import reducers from './reducers';
import { createRequestsFactoryMiddleware } from 'redux-requests-factory';

export const history = createBrowserHistory();
const epicMiddleware = createEpicMiddleware();
const { middleware: requestsFactoryMiddleware } = createRequestsFactoryMiddleware();

const composeEnhacers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
  const middleware = [routerMiddleware(history), epicMiddleware, requestsFactoryMiddleware];

  const store = createStore(reducers(history), composeEnhacers(applyMiddleware(...middleware)));

  epicMiddleware.run(epics);

  return store;
}
