import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { clientInfoAction } from './action';
import { forcedLoadDataGetClientAction } from '../../../../api/requests/client';

const initClientInfoEpic = (action$) =>
  action$.pipe(
    ofType(clientInfoAction),
    map(({ payload }) => forcedLoadDataGetClientAction(payload))
  );

export default initClientInfoEpic;
