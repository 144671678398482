import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

const useErrorReset = (errorMessagesAndReset, handleOccurredError, hideErrorModal) => {
  const dispatch = useDispatch();
  const errorMessageAndReset = useMemo(
    () => errorMessagesAndReset?.find((findObjectError) => findObjectError?.message && findObjectError),
    [errorMessagesAndReset]
  );

  const handleResetError = useCallback(() => {
    hideErrorModal();
    dispatch(errorMessageAndReset?.resetAction());
  }, [dispatch, errorMessageAndReset, hideErrorModal]);

  useEffect(() => {
    if (errorMessageAndReset?.message) {
      handleOccurredError(errorMessageAndReset?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessagesAndReset]);

  return handleResetError;
};

export default useErrorReset;
