import { useEffect } from 'react';

const useOutsideAlerter = (ref, setToggleMenu) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current?.contains(event.target)) return;
      if (ref?.current) {
        setToggleMenu(false);
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, setToggleMenu]);
};

export default useOutsideAlerter;
