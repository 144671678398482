import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';

export const getDownloadFileRequest = (params) => api.get(ENDPOINTS.DOWNLOAD_FILE(params));

export const {
  loadDataAction: loadDataDownloadFileAction,
  doRequestAction: doRequestDownloadFileAction,
  resetRequestAction: resetRequestDownloadFileAction,
  forcedLoadDataAction: forcedLoadDataDownloadFileAction,
  requestFulfilledAction: requestFulfilledDownloadFileAction,
  requestRejectedAction: requestRejectedDownloadFileAction,
  requestStatusSelector: requestStatusDownloadFileSelector,
  responseSelector: responseDownloadFileSelector,
  errorSelector: errorDownloadFileSelector,
  isLoadedSelector: isLoadedDownloadFileSelector,
} = requestsFactory({
  request: getDownloadFileRequest,
  stateRequestKey: 'download_file_get',
});
