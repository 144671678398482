import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';

export const getUserListRequest = (params) => api.get(ENDPOINTS.USER.USER_LIST(params));

export const {
  loadDataAction: loadDataGetUserListAction,
  doRequestAction: doRequestGetUserListAction,
  resetRequestAction: resetRequestGetUserListAction,
  forcedLoadDataAction: forcedLoadDataGetUserListAction,
  requestFulfilledAction: requestFulfilledGetUserListAction,
  requestRejectedAction: requestRejectedGetUserListAction,
  responseSelector: responseGetUserListSelector,
  errorSelector: errorGetUserListSelector,
  isLoadedSelector: isLoadedGetUserListSelector,
} = requestsFactory({
  request: getUserListRequest,
  stateRequestKey: 'user_list_get',
  // fulfilledActions: [() => forcedLoadDataGetUserListAction()],
});
