import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';
import { forcedLoadDataGetClientAction } from './get';

export const patchClientRequest = ({ id, name, email, description, phone_number, site_address, is_active }) =>
  api.patch(ENDPOINTS.CLIENT.CLIENT_DETAILS(id), { name, email, description, phone_number, site_address, is_active });

export const {
  loadDataAction: loadDataPatchClientAction,
  doRequestAction: doRequestPatchClientAction,
  resetRequestAction: resetRequestPatchClientAction,
  forcedLoadDataAction: forcedLoadDataPatchClientAction,
  requestFulfilledAction: requestFulfilledPatchClientAction,
  requestRejectedAction: requestRejectedPatchClientAction,
  responseSelector: responsePatchClientSelector,
  errorSelector: errorPatchClientSelector,
  isLoadedSelector: isLoadedPatchClientSelector,
} = requestsFactory({
  request: patchClientRequest,
  stateRequestKey: 'client_patch',
  fulfilledActions: [({ _, request: { id } }) => forcedLoadDataGetClientAction(id)],
});
