import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';
import { forcedLoadDataGetUserListAction } from './get';

export const postUserRequest = (params) => api.post(ENDPOINTS.USER.USER_LIST(), params);

export const {
  doRequestAction: doRequestPostUserAction,
  requestFulfilledAction: requestFulfilledPostUserAction,
  requestRejectedAction: requestRejectedPostUserAction,
  resetRequestAction: resetRequestPostUserAction,
  errorSelector: errorPostUserSelector,
  isLoadedSelector: isLoadedPostUserSelector,
  requestStatusSelector: requestStatusPostUserSelector,
} = requestsFactory({
  request: postUserRequest,
  stateRequestKey: 'user_post',
  fulfilledActions: [() => forcedLoadDataGetUserListAction()],
});
