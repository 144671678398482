import styled from 'styled-components';

export const ModalContent = styled.div.attrs({ className: 'model-content' })`
  border: 3px solid grey;
`;

export const ModalHeader = styled.div.attrs({ className: 'modal-header' })`
  border: none;
`;

export const Span = styled.div.attrs({ className: 'modal-title' })`
  color: grey;
`;

export const ModalFooter = styled.div.attrs({ className: 'modal-footer' })`
  justify-content: flex-start;
  border: none;
`;

export const Button = styled.button`
  padding: 5px 45px;
  border: solid 2px #ff7b00;
  background: #ffffff;
`;
