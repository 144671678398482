import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';

export const deleteTerminalRequest = (id) => api.delete(ENDPOINTS.TERMINAL.TERMINAL_DELETE(id));

export const {
  doRequestAction: doRequestDeleteTerminalAction,
  requestFulfilledAction: requestFulfilledDeleteTerminalAction,
  requestRejectedAction: RequestRejectedDeleteTerminalAction,
  selectIsLoading: selectDeleteTerminalIsLoading,
} = requestsFactory({
  request: deleteTerminalRequest,
  stateRequestKey: 'terminal_delete',
});
