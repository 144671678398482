import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { forcedLoadDataGetLocationAction } from '../../../../api/requests/location';
import { locationInfoAction } from './actions';

const initLocationInfoEpic = (action$) =>
  action$.pipe(
    ofType(locationInfoAction),
    mergeMap(({ payload }) => [forcedLoadDataGetLocationAction(payload)])
  );

export default initLocationInfoEpic;
