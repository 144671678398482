import { api } from '../../api';
import { requestsFactory } from 'redux-requests-factory';
import { ENDPOINTS } from '../../constants';
import { forcedLoadDataGetLocationAction } from './get';

export const patchLocationRequest = ({ id, raw_address, is_active, phone_number, email, client, name }) =>
  api.patch(ENDPOINTS.LOCATION.LOCATION_DETAILS(id), { raw_address, is_active, phone_number, email, client, name });

export const {
  loadDataAction: loadDataPatchLocationAction,
  doRequestAction: doRequestPatchLocationAction,
  resetRequestAction: resetRequestPatchLocationAction,
  forcedLoadDataAction: forcedLoadDataPatchLocationAction,
  requestFulfilledAction: requestFulfilledPatchLocationAction,
  requestRejectedAction: requestRejectedPatchLocationAction,
  responseSelector: responsePatchLocationSelector,
  errorSelector: errorPatchLocationSelector,
  isLoadedSelector: isLoadedPatchLocationSelector,
} = requestsFactory({
  request: patchLocationRequest,
  stateRequestKey: 'location_patch',
  fulfilledActions: [({ _, request: { id } }) => forcedLoadDataGetLocationAction(id)],
});
